import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { assignSalary, getEmployees, getLeaders } from "../../http";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

const AssignSalary = () => {
  const initialState = {
  
    bonus: '',
    reasonForBonus: '',
    salary: '',
    baseSalary: '',
    payFrequency: 'Monthly',
    currency: 'USD',
    bonusEligibility: false,
    allowances: '',
    benefits: {
      healthInsurance: false,
      retirementPlan: false,
    },
    taxInformation: {
      taxID: '',
      withholdingPercentage: '',
    },
    bankAccountDetails: {
      bankName: '',
      accountNumber: '',
      routingNumber: '',
    },
  };

  const [formData, setFormData] = useState(initialState);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const fetchEmployees = async () => {
      const emps = await getEmployees();
      const leaders = await getLeaders();
      setEmployees([...emps.data.data, ...leaders.data.data]);
    };
    fetchEmployees();
  }, []);

  const inputEvent = (e) => {
    const { name, value, type, checked } = e.target;
  
    // Handle nested state updates
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData((prevState) => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: type === "checkbox" ? checked : value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };
  
  

  const onSubmit = async (e) => {
    e.preventDefault();

    if (formData.taxInformation.withholdingPercentage >= 100) {
      return toast.error("Withholding percentage within  1-100.");
    }
    // Check for required fields
    const requiredFields = [
      'selectedEmployee',
      'salary',
      'baseSalary',
      'allowances',
      'taxInformation.taxID',
      'taxInformation.withholdingPercentage',
      'bankAccountDetails.bankName',
      'bankAccountDetails.accountNumber',
      'bankAccountDetails.routingNumber',
    ];

    for (const field of requiredFields) {
      const value = field.split('.').reduce((acc, part) => acc && acc[part], formData);
      if (!value) {
        toast.error(`${field.replace('.', ' ').toUpperCase()} is required`);
        return;
      }
    }

    formData["employeeID"] = selectedEmployee;
     try{
       const res = await assignSalary(formData);
       const { success } = res.data;
       if (success) {
         toast.success("Salary Assigned!");
       }
     }catch(e){
      if (e.response && e.response.data) {
        const errorMessage = e.response.data.message;
        toast.error(errorMessage);
      } else {
        console.log("An unexpected error occurred",e);
      }
     }
  
    setFormData(initialState);
  };

  return (
    <>
      <div className="main-content">
        <section className="section">
          <HeaderSection title="Assign Salary" />
          <div className="card">
            <div className="card-body pr-5 pl-5 m-1">
              <form className="row" onSubmit={onSubmit} id="assignSalaryForm">
                {/* Employee Selection */}
                <div className="form-group col-md-4">
                  <label>Employees</label>
                  <select
                    className="form-control select2"
                    value={selectedEmployee}
                    onChange={(e) => setSelectedEmployee(e.target.value)}
                  >
                    <option value="">Select Employee</option>
                    {employees?.map((employee) => (
                      <option key={employee._id} value={employee.id}>
                        {employee.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Salary Grade */}
                <div className="form-group col-md-4">
                  <label>Salary Grade/Level</label>
                  <input
                    onChange={inputEvent}
                    value={formData.salary}
                    type="text"
                    name="salary"
                    className="form-control"
                    required
                  />
                </div>

                {/* Base Salary */}
                <div className="form-group col-md-4">
                  <label>Base Salary</label>
                  <input
                    onChange={inputEvent}
                    value={formData.baseSalary}
                    type="number"
                    name="baseSalary"
                    className="form-control"
                    required
                  />
                </div>

                {/* Pay Frequency */}
                <div className="form-group col-md-4">
                  <label>Pay Frequency</label>
                  <select
                    onChange={inputEvent}
                    value={formData.payFrequency}
                    name="payFrequency"
                    className="form-control"
                  >
                    <option value="Monthly">Monthly</option>
                    <option value="Biweekly">Biweekly</option>
                  </select>
                </div>

                {/* Currency */}
                <div className="form-group col-md-4">
                  <label>Currency</label>
                  <select
                    onChange={inputEvent}
                    value={formData.currency}
                    name="currency"
                    className="form-control"
                  >
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                    <option value="GBP">GBP</option>
                    <option value="INR">INR</option>
                  </select>
                </div>

                {/* Bonus Eligibility */}
                <div className="form-group col-md-4">
                  <label>Bonus Eligibility</label>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="bonusEligibility"
                      className="form-check-input"
                      checked={formData.bonusEligibility}
                      onChange={inputEvent}
                    />
                    <label className="form-check-label">Eligible for Bonus</label>
                  </div>
                </div>

                {/* Bonus Amount (Only if Eligible) */}
                {formData.bonusEligibility && (
                  <div className="form-group col-md-4">
                    <label>Bonus Amount</label>
                    <input
                      onChange={inputEvent}
                      value={formData.bonus}
                      type="number"
                      name="bonus"
                      className="form-control"
                    />
                  </div>
                )}

                {/* Reason for Bonus */}
                {formData.bonusEligibility && (
                <div className="form-group col-md-4">
                  <label>Reason for Bonus</label>
                  <input
                    onChange={inputEvent}
                    value={formData.reasonForBonus}
                    type="text"
                    name="reasonForBonus"
                    className="form-control"
                    
                  />
                </div>
                )}

                {/* Allowances */}
                <div className="form-group col-md-4">
                  <label>Allowances</label>
                  <input
                    onChange={inputEvent}
                    value={formData.allowances}
                    type="text"
                    name="allowances"
                    className="form-control"
                    required
                  />
                </div>

                {/* Benefits */}
                <div className="form-group col-md-4">
                  <label>Benefits</label>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="benefits.healthInsurance"
                      className="form-check-input"
                      checked={formData.benefits.healthInsurance}
                      onChange={inputEvent}
                    />
                    <label className="form-check-label">Health Insurance</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      name="benefits.retirementPlan"
                      className="form-check-input"
                      checked={formData.benefits.retirementPlan}
                      onChange={inputEvent}
                    />
                    <label className="form-check-label">Retirement Plan</label>
                  </div>
                </div>

                {/* Tax Information */}
                <div className="form-group col-md-4">
                  <label>Tax ID</label>
                  <input
                    onChange={inputEvent}
                    value={formData.taxInformation.taxID}
                    type="text"
                    name="taxInformation.taxID"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group col-md-4">
                  <label>Withholding Percentage</label>
                  <input
                    onChange={inputEvent}
                    value={formData.taxInformation.withholdingPercentage}
                    type="number"
                    name="taxInformation.withholdingPercentage"
                    className="form-control"
                    max="100"
                    min="0"
                    required
                  />
                </div>

                {/* Bank Account Details */}
                <div className="form-group col-md-4">
                  <label>Bank Name</label>
                  <input
                    onChange={inputEvent}
                    value={formData.bankAccountDetails.bankName}
                    type="text"
                    name="bankAccountDetails.bankName"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group col-md-4">
                  <label>Account Number</label>
                  <input
                    onChange={inputEvent}
                    value={formData.bankAccountDetails.accountNumber}
                    type="text"
                    name="bankAccountDetails.accountNumber"
                    className="form-control"
                    required
                  />
                </div>

                <div className="form-group col-md-4">
                  <label>Routing Number</label>
                  <input
                    onChange={inputEvent}
                    value={formData.bankAccountDetails.routingNumber}
                    type="text"
                    name="bankAccountDetails.routingNumber"
                    className="form-control"
                    required
                  />
                </div>

                {/* Submit Button */}
                <div className="form-group text-center col-md-12">
                  <button className="btn btn-primary btn-lg" type="submit" style={{ width: "30vh" }}>
                    Assign Salary
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AssignSalary;

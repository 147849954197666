import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { getAllUserExceptAdmin, addJobDetails } from "../../http";

const AddJobUser = () => {
  const initialState = {
    userId: "",
    department: "",
   
    esd: "",
    jd: "",
    joblocation: "",
    jobtitle: "",
    workshift: "",
    employmentStatus: "Full-time", // Default value
    employmentType: "Permanent" // Default value
  };

  const [formData, setFormData] = useState(initialState);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await getAllUserExceptAdmin();
        if (response.data.success) {
          setAllUsers(response.data.data);
        } else {
          toast.error("Unable to fetch user records");
        }
      } catch (error) {
        toast.error("Error fetching users");
      }
    };
    fetchUsers();
  }, []);

  const inputEvent = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const {
      userId,
      department,
      
      esd,
      jd,
      joblocation,
      jobtitle,
      workshift,
      employmentStatus,
      employmentType
    } = formData;

    if (
      !userId ||
      !department ||
     
      !esd ||
      !jd ||
      !joblocation ||
      !jobtitle ||
      !workshift ||
      !employmentStatus ||
      !employmentType
    ) {
      console.log(formData);
      return toast.error("All fields are required.");
    }

    console.log(formData);
    try {
      const res = await addJobDetails(formData);
      if (res.data.success) {
        setFormData({ ...initialState });
        toast.success(res.data.message || "Job description added successfully!");
      } else {
        toast.error("Failed to add job description.");
      }
    } catch (error) {
      toast.error("An error occurred while adding job description.");
    }
  };

  return (
    <>
      <div className="main-content">
        <section className="section">
          <HeaderSection title="Add Job Description" />
          <div className="card">
            <div className="card-body pr-5 pl-5 m-1">
              <form className="row" onSubmit={onSubmit}>
                {/* Select Employee */}
                <div className="form-group col-md-4">
                  <label>Select Employee</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-user"></i>
                      </div>
                    </div>
                    <select
                      value={formData.userId}
                      onChange={inputEvent}
                      id="userId"
                      name="userId"
                      className="form-control"
                    >
                      <option value="" disabled>
                        Select a user
                      </option>
                      {allUsers.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Job Title */}
                <div className="form-group col-md-4">
                  <label>Enter Job Title</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.jobtitle}
                      type="text"
                      id="jobtitle"
                      name="jobtitle"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Department */}
                <div className="form-group col-md-4">
                  <label>Enter Department</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.department}
                      type="text"
                      id="department"
                      name="department"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Employment Status */}
                <div className="form-group col-md-4">
                  <label>Select Employment Status</label>
                  <select
                    name="employmentStatus"
                    onChange={inputEvent}
                    value={formData.employmentStatus}
                    className="form-control select2"
                  >
                    <option value="" disabled>
                      Select Employment Status
                    </option>
                    <option value="Full-time">Full-time</option>
                    <option value="Part-time">Part-time</option>
                    <option value="Contract">Contract</option>
                  </select>
                </div>

                {/* Employment Type */}
                <div className="form-group col-md-4">
                  <label>Select Employment Type</label>
                  <select
                    name="employmentType"
                    onChange={inputEvent}
                    value={formData.employmentType}
                    className="form-control select2"
                  >
                    <option value="" disabled>
                      Select Employment Type
                    </option>
                    <option value="Permanent">Permanent</option>
                    <option value="Temporary">Temporary</option>
                    <option value="Internship">Internship</option>
                  </select>
                </div>

                {/* Employment Start Date */}
                <div className="form-group col-md-4">
                  <label>Employment Start Date</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.esd}
                      type="date"
                      id="esd"
                      name="esd"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Employment End Date */}
                {/* <div className="form-group col-md-4">
                  <label>Employment End Date</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-calendar-alt"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.eed}
                      type="date"
                      id="eed"
                      name="eed"
                      className="form-control"
                    />
                  </div>
                </div> */}

                {/* Job Location */}
                <div className="form-group col-md-4">
                  <label>Job Location</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-map-marker-alt"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.joblocation}
                      type="text"
                      id="joblocation"
                      name="joblocation"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Work Shift */}
                <div className="form-group col-md-4">
                  <label>Work Shift</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-clock"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.workshift}
                      type="text"
                      id="workshift"
                      name="workshift"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Job Description */}
                <div className="form-group col-md-12">
                  <label>Enter Job Description</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-file-alt"></i>
                      </div>
                    </div>
                    <input
                      onChange={inputEvent}
                      value={formData.jd}
                      type="text"
                      id="jd"
                      name="jd"
                      className="form-control"
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="form-group text-center col-md-12">
                  <button
                    className="btn btn-primary btn-lg"
                    type="submit"
                    style={{ width: "30vh" }}
                  >
                    Add Job Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddJobUser;

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import { dLogout } from "../http";
import { setAuth } from "../store/auth-slice";
import axios from "axios";
import {toast} from "react-toastify";
import moment from 'moment';
import { useEffect,useState } from "react";
import { fetchAllAdminNotificationsAPI,userLeaveNotification,notificationMarkAsRead,unreadNotificationCountByUserId } from "../http";
import Notification from "./notification";
import "../css/navigation.css";




const Navigation = () =>
{
    const {name,image,type} = useSelector((state)=>state.authSlice.user);
    const userId = JSON.parse(localStorage.getItem('user'));
    const [newNotification,setNewNotification]= useState(false);

    console.log(image);
   
    console.log(type);
    
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();

        const fetchAllAdminNotifications = async () => {
            try {
              const response = await fetchAllAdminNotificationsAPI();
              
              return response.data;
            } catch (error) {
              console.error("Error fetching all admin notifications:", error);
              throw error;
            }
          };
    
          const MarkAsReadHandler = async () => {
            try {
              // Call the API to mark notifications as read
              const response = await notificationMarkAsRead(userId.id);
          
              // Check if the response indicates success
              if (response?.data?.success) {
                // Update the UI state
                setNewNotification(false); // Removes the dot icon
                setNotifications((prevNotifications) =>
                  prevNotifications.map((notification) => ({
                    ...notification,
                    isRead: true, // Mark each notification as read in the local state
                  }))
                );
                toast.success("All notifications marked as read!");
                window.location.reload();
              } else {
                // Handle a case where the API does not return success
                toast.error(response?.data?.message || "Failed to mark notifications as read.");
              }
            } catch (error) {
              // Log the error and provide user feedback
              console.error("Error marking notifications as read:", error);
          
              // Display a user-friendly error message
              toast.error(
                error?.response?.data?.message || "An error occurred while marking notifications as read. Please try again later."
              );
            }
          };
          
          

      useEffect(() => {
        // Fetch all admin notifications when the component mounts
        if (type == "Admin")
        {  
        fetchAllAdminNotifications()
          .then(data => {
            data = data.filter((data) => data.metadata.actionBy !== name);
            console.log(data);
            setNotifications(data)})
          
          .catch(error => console.error("Failed to load all admin notifications:", error));
        }
        else
        {
            userLeaveNotification(userId.id)
                .then(data => {
                    console.log(data);  // Log the data
                    setNotifications(data.data);
                })
                .catch(error => console.error("Failed to load all admin notifications:", error));

        }
      }, []);
    
    useEffect(async ()=>{
       
        try{
            const response = await unreadNotificationCountByUserId(userId.id);

            if(response.data.unreadCount == 0)
            {
                setNewNotification(false);
            }
            else
            {
                setNewNotification(true);
            }
           

        }
        catch(error)
        {
            console.log("Error",error)
        }
    },[]);

    const logout = async () =>
    {
        // const data = await dLogout();
        // console.log(data);
        // dispatch(setAuth(null))
        // return history.push('/login');
        localStorage.clear();
        // Reset authentication state
        dispatch(setAuth(null));
    
        // Redirect to login page
        history.push('/login');
    }

    return(
        <>

        <div className="navbar-bg"></div>
            <nav className="navbar navbar-expand-lg main-navbar">
                <form className="form-inline mr-auto">
                <ul className="navbar-nav mr-3">
                    <li><NavLink to='/' id='sidebarCollapse' data-toggle="sidebar" className="nav-link nav-link-lg"><i className="fas fa-bars"></i></NavLink></li>
                    <li><NavLink to='/' data-toggle="search" className="nav-link nav-link-lg d-sm-none"><i className="fas fa-search"></i></NavLink></li>
                </ul>

                </form>

                <ul className="navbar-nav navbar-right">
                {/* <Notification /> */}
                <li className="dropdown dropdown-list-toggle"><a href='#' data-toggle="dropdown" className={
      newNotification
        ? "nav-link notification-toggle nav-link-lg beep"
        : "nav-link notification-toggle nav-link-lg"
    }>
                     <i className="far fa-bell"></i>
                
                    </a>
                    <div className="dropdown-menu dropdown-list dropdown-menu-right">
                    <div className="dropdown-header notification-title">Notifications
                        <p className="float-right markAsRead" onClick={MarkAsReadHandler}>Mark All As Read</p>
                    </div>
                    <div className="notification-container">
                    {
                        notifications.map((notification)=>(  
                            <NavLink to='/'>
                        <div className="nav-item-container " >
                        <img
                              
                              src={notification.userId?.image}
                              alt={notification.userId?.image}

                              className="notification-profile-image"
                              style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                            />
                       
                        <div className="nav-item-content-container">
                        <p className="notification-item-userName">{notification.userId?.name}</p>
                              <p className="notification-item-title">{notification.title}</p>
                              
                            <div className="time">{moment(notification.createdAt).isValid() ? moment(notification.createdAt).fromNow() : 'Invalid Date'}</div>
                            
                        </div>
                        </div>
                        </NavLink>

                          
                          
                                
                        ))
                    }
                    </div>
                    
                    <div className="dropdown-footer text-center">
                        <NavLink to='/'>View All <i className="fas fa-chevron-right"></i></NavLink>
                    </div>
                    </div>
                </li>
                <li className="dropdown"><a href='#' data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
                    <img alt="image" src={image} className="rounded-circle mr-1"/>
                    <div className="d-sm-none d-lg-inline-block">Hi, {name}</div></a>
                    <div className="dropdown-menu dropdown-menu-right">
                    {/* <div className="dropdown-title">Logged in 5 min ago</div> */}
                    {/* <NavLink to="features-profile.html" className="dropdown-item has-icon">
                        <i className="far fa-user"></i> Profile
                    </NavLink>
                    <NavLink to="features-activities.html" className="dropdown-item has-icon">
                        <i className="fas fa-bolt"></i> Activities
                    </NavLink>
                    <NavLink to="features-settings.html" className="dropdown-item has-icon">
                        <i className="fas fa-cog"></i> Settings
                    </NavLink> */}
                    <div className="dropdown-divider"></div>
                    <NavLink to='/' onClick={logout} className="dropdown-item has-icon text-danger">
                        <i className="fas fa-sign-out-alt"></i> Logout
                    </NavLink>
                    </div>
                </li>
                </ul>
            </nav>
        </>
    )
}

export default Navigation;
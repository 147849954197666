import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCounts } from "../../http";
import { setCount } from "../../store/main-slice";
import CountsCard from './CountsCard';

const Admin = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { counts } = useSelector((state) => state.mainSlice);
  const { admin = 0, employee = 0, leader = 0, team = 0 } = counts || {};

  useEffect(() => {
    (async () => {
      try {
        const res = await getCounts();
        if (res.data.success) {
          dispatch(setCount(res.data.data));
        } else {
          throw new Error("Failed to fetch counts data");
        }
      } catch (error) {
        setError("Failed to fetch counts.");
        console.error("Failed to fetch counts:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [dispatch]);

  const countCards = useMemo(() => [
    { title: 'Total Employee', icon: 'fa-user', count: employee },
    { title: 'Total Leader', icon: 'fa-user', count: leader },
    { title: 'Total Admin', icon: 'fa-user', count: admin },
    { title: 'Total Team', icon: 'fa-user', count: team },
  ], [employee, leader, admin, team]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="row justify-content-center">
      {countCards.map((card, index) => (
        <CountsCard key={index} title={card.title} icon={card.icon} count={card.count} />
      ))}
    </div>
  );
};

export default Admin;

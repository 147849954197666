import { useState } from "react";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { addTeam } from "../../http";

const AddTeam = () => {
    const initialState = { name: '', description: '', image: '' };
    const [imagePreview, setImagePreview] = useState('/assets/icons/team.png');
    const [formData, setFormData] = useState(initialState);
    const [errors, setErrors] = useState({});

    const inputEvent = (e) => {
        const { name, value } = e.target;

        if (/^[a-zA-Z\s]*$/.test(value)) {
            setFormData((old) => ({
                ...old,
                [name]: value
            }));
            setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Only alphabetic characters are allowed' }));
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const { name, description, image } = formData;

        // Check if required fields are filled, including image
        if (!name || !description || !image) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: !name ? 'Team Name is required' : '',
                description: !description ? 'Description is required' : '',
                image: !image ? 'Image upload is required' : '',
            }));
            return toast.error('All fields, including image, are required');
        }

        const fd = new FormData();
        Object.keys(formData).forEach((key) => {
            fd.append(key, formData[key]);
        });

        try {
            const res = await addTeam(fd);

            if (res?.data?.success) {
                setFormData({ ...initialState });
                setImagePreview('/assets/icons/team.png');
                toast.success(res.data.message);
            } else {
                toast.error(res?.data?.message || 'Failed to add team. Please try again.');
            }
        } catch (error) {
            console.error("Error adding team:", error);
            toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    const captureImage = (e) => {
        const file = e.target.files[0];

        if (file && !file.type.startsWith('image/')) {
            return toast.error('Please upload a valid image file');
        }
        if (file && file.size > 1024 * 1024) {
            return toast.error('Image size should not exceed 1MB');
        }

        setFormData((old) => ({
            ...old,
            image: file
        }));
        setErrors((prevErrors) => ({ ...prevErrors, image: '' })); // Clear error if image is valid

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => setImagePreview(reader.result);
    }

    return (
        <>
            <div className="main-content">
                <section className="section">
                    <HeaderSection title='Create Team' />
                    <div className="card">
                        <div className="card-body pr-5 pl-5 m-1">
                            <form className='row' onSubmit={onSubmit}>
                                <div className="form-group col-md-12 text-center">
                                    <div className="input-group justify-content-center">
                                        <input
                                            type="file"
                                            id='image'
                                            name='image'
                                            className="form-control d-none"
                                            onChange={captureImage}
                                            accept="image/*"
                                        />
                                        <label htmlFor='image'>
                                            <img
                                                className='rounded'
                                                src={imagePreview}
                                                width='120'
                                                alt=""
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </label>
                                    </div>
                                    {errors.image && <small className="text-danger">{errors.image}</small>}
                                </div>

                                <div className="form-group col-md-12">
                                    <label>Enter Team Name</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <input
                                            onChange={inputEvent}
                                            value={formData.name}
                                            type="text"
                                            id='name'
                                            name='name'
                                            className="form-control"
                                            placeholder="Only alphabetic characters"
                                        />
                                    </div>
                                    {errors.name && <small className="text-danger">{errors.name}</small>}
                                </div>

                                <div className="form-group col-md-12">
                                    <label>Enter Team Description</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-file-alt"></i>
                                            </div>
                                        </div>
                                        <input
                                            onChange={inputEvent}
                                            value={formData.description}
                                            type="text"
                                            id='description'
                                            name='description'
                                            className="form-control"
                                            placeholder="Only alphabetic characters"
                                        />
                                    </div>
                                    {errors.description && <small className="text-danger">{errors.description}</small>}
                                </div>

                                <div className="form-group text-center col-md-12">
                                    <button className='btn btn-primary btn-lg' type='submit' style={{ width: '30vh' }}>Add Team</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default AddTeam;

import { useEffect, useState } from "react";
import HeaderSection from "../../components/HeaderSection";
import RowEmployee from "../../components/rows/row-employee";
import { getEmployees } from "../../http";

const Employees = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of employees to display per page

    useEffect(() => {
        (async () => {
            const res = await getEmployees();
            console.log(res);
            if (res.data) {
                setUsers(res.data.data);
                setLoading(false);
            }
        })();
    }, []);

    // Calculate total pages
    const totalPages = Math.ceil(users.length / itemsPerPage);

    // Get current page's employees
    const currentEmployees = users.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle page change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="main-content">
                <section className="section">
                    <HeaderSection title="Employees" />
                    <div className="card">
                        <div className="card-header">
                            <h4>All Employees</h4>
                        </div>
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table table-striped table-md center-text">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Status</th>
                                            <th>Team</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading &&
                                            currentEmployees.map((data, index) => {
                                                return (
                                                    <RowEmployee
                                                        key={index}
                                                        index={(currentPage - 1) * itemsPerPage + index + 1}
                                                        data={data}
                                                    />
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            {/* Bootstrap Pagination Controls */}
                            <nav aria-label="Page navigation">
                                <ul className="pagination justify-content-center m-3">
                                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1}
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <li
                                            key={i}
                                            className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                                        >
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(i + 1)}
                                            >
                                                {i + 1}
                                            </button>
                                        </li>
                                    ))}
                                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === totalPages}
                                        >
                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Employees;

import { useState } from "react";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { addUser } from "../../http";
import Modal from "../../components/modal/Modal";

const AddUser = () => {
    const initialState = {
        name: '',
        email: '',
        mobile: '',
        password: '',
        type: 'Employee',
        address: '',
        profile: '',
        adminPassword: '',
        dob: '',
        gender: 'Male',
        marital: 'Single',
        contactName: '',
        contactRelationship: '',
        contactNumber: '',
        // sin: '',
        nationality: '',
        country: '',
        state: '',
        city: '',
        postalCode: ''
    };

    const [formData, setFormData] = useState(initialState);
    const [imagePreview, setImagePreview] = useState('/assets/icons/user.png');
    const [showModal, setShowModal] = useState(false);

    const inputEvent = (e) => {
        const { name, value } = e.target;
        setFormData((old) => ({
            ...old,
            [name]: value
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();
    
        const { 
            name, email, mobile, password, type, address, profile, dob, contactName, 
            contactRelationship, contactNumber, postalCode, nationality, country, state, city 
        } = formData;
    
        // Validation
        const alphabeticRegex = /^[A-Za-z\s]+$/; // Regex for alphabetic characters and spaces
    
        if (!name || !alphabeticRegex.test(name)) return toast.error("Name must contain only alphabets and spaces.");
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return toast.error("Enter a valid email address.");
        if (!mobile || mobile.length !== 10 || isNaN(mobile)) return toast.error("Mobile number must be 10 digits.");
        if (!password || password.length < 6) return toast.error("Password must be at least 6 characters.");
        if (!dob || new Date(dob) >= new Date()) return toast.error("Date of birth must be in the past.");
        if (!contactName || !alphabeticRegex.test(contactName)) return toast.error("Emergency contact name must contain only alphabets and spaces.");
        if (!contactRelationship || !alphabeticRegex.test(contactRelationship)) return toast.error("Emergency contact relationship must contain only alphabets and spaces.");
        if (!contactNumber || contactNumber.length !== 10 || isNaN(contactNumber)) return toast.error("Emergency contact number must be 10 digits.");
        if (!postalCode || postalCode.length < 5 || postalCode.length > 10) return toast.error("Postal code must be between 5 and 10 characters.");
        if (!address) return toast.error("Address is required.");
        if (!profile) return toast.error("Please upload a profile image.");
        if (!nationality) return toast.error("Nationality is required.");
        if (!country || !state || !city) return toast.error("Country, State, and City are required.");
    
        // Additional check for Admin
        if (type === "Admin" && !showModal) {
            setShowModal(true);
            return;
        }
    
        // Create FormData object for file upload
        const fd = new FormData();
        Object.keys(formData).forEach((key) => {
            fd.append(key, formData[key]);
        });
    
        try {
            const res = await addUser(fd);
            if (res.data.success) {
                toast.success(res.data.message);
                setShowModal(false);
                setFormData({ ...initialState });
                setImagePreview('/assets/icons/user.png'); // Reset image preview
            }
        } catch (error) {
            console.error("Error adding user:", error);
            toast.error("Failed to add user");
        }
    };
    

    const captureImage = (e) => {
        const file = e.target.files[0];
        if (file.size > 1 * 1024 * 1024) {  // 1MB size limit
            return toast.error("File size should not exceed 1MB");
        }

        setFormData((prevData) => ({
            ...prevData,
            profile: file
        }));

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
    };

    const modalAction = () => setShowModal(!showModal);

    return (
        <>
            {showModal && 
                <Modal close={modalAction} title="Add Admin" width='35%'>
                    <div className="row" style={{ margin: '20px' }}>
                        <div className="col-md-4 text-center">
                            <img className='rounded' src={imagePreview} width='120' alt="" /> 
                        </div>
                        <div className="col-md-8">
                            <table className='table table-md'>
                                <tbody>
                                    <tr><th>Name</th><td>{formData.name}</td></tr>
                                    <tr><th>Email</th><td>{formData.email}</td></tr>
                                    <tr><th>User Type</th><td>{formData.type}</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Enter Your Password</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><i className="fas fa-lock"></i></div>
                            </div>
                            <input
                                onChange={inputEvent}
                                value={formData.adminPassword}
                                type="password"
                                placeholder={`Enter Your Password To Add ${formData.name} As An Admin`}
                                id='adminPassword'
                                name='adminPassword'
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="text-center mb-3">
                        <button className='btn btn-primary btn-lg' type='submit' form='addUserForm' style={{ width: '30vh' }}>Add {formData.type}</button>
                    </div>
                </Modal>
            }

            <div className="main-content">
                <section className="section">
                    <HeaderSection title='Add User' />
                    <div className="card">
                        <div className="card-body pr-5 pl-5 m-1">
                            <form className='row' onSubmit={onSubmit} id='addUserForm'>
                                <div className="form-group col-md-12 text-center">
                                    <label htmlFor='profile'>
                                        <img className='rounded' src={imagePreview} width='120' alt="" />
                                    </label>
                                    <input
                                        type="file"
                                        id='profile'
                                        name='profile'
                                        className="form-control d-none"
                                        onChange={captureImage}
                                        accept="image/*"
                                    />
                                </div>

                                {/* Basic Information Fields */}
                                <div className="form-group col-md-6">
                                    <label>Enter Name</label>
                                    <input onChange={inputEvent} value={formData.name} type="text" id='name' name='name' className="form-control"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Enter Email</label>
                                    <input onChange={inputEvent} value={formData.email} type="email" id='email' name='email' className="form-control"/>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Date Of Birth</label>
                                    <input onChange={inputEvent} value={formData.dob} type="date" id='dob' name='dob' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Enter Mobile Number</label>
                                    <input onChange={inputEvent} value={formData.mobile} type="number" id='mobile' name='mobile' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Gender</label>
                                    <select name='gender' onChange={inputEvent} value={formData.gender} className="form-control">
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Marital Status</label>
                                    <select name='marital' onChange={inputEvent} value={formData.marital} className="form-control">
                                        <option>Single</option>
                                        <option>Married</option>
                                    </select>
                                </div>

                                {/* Emergency Contact Information */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Name</label>
                                    <input onChange={inputEvent} value={formData.contactName} type="text" id='contactName' name='contactName' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Relationship</label>
                                    <input onChange={inputEvent} value={formData.contactRelationship} type="text" id='contactRelationship' name='contactRelationship' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Number</label>
                                    <input onChange={inputEvent} value={formData.contactNumber} type="number" id='contactNumber' name='contactNumber' className="form-control"/>
                                </div>

                              
                                <div className="form-group col-md-4">
                                    <label>Enter Password</label>
                                    <input onChange={inputEvent} value={formData.password} type="password" id='password' name='password' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>User Type</label>
                                    <select name='type' onChange={inputEvent} value={formData.type} className="form-control">
                                        <option>Employee</option>
                                        <option>Leader</option>
                                        <option>Admin</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Nationality</label>
                                    <input onChange={inputEvent} value={formData.nationality} type="text" id='nationality' name='nationality' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Country</label>
                                    <input onChange={inputEvent} value={formData.country} type="text" id='country' name='country' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>State</label>
                                    <input onChange={inputEvent} value={formData.state} type="text" id='state' name='state' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>City</label>
                                    <input onChange={inputEvent} value={formData.city} type="text" id='city' name='city' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Postal Code</label>
                                    <input onChange={inputEvent} value={formData.postalCode} type="text" id='postalCode' name='postalCode' className="form-control"/>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Enter Address</label>
                                    <textarea onChange={inputEvent} value={formData.address} type="text" id='address' name='address' className="form-control"></textarea>
                                </div>

                                <div className="form-group col-md-12 text-center">
                                    <button className='btn btn-primary' type='submit'>Add {formData.type}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default AddUser;

import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
});

console.log(api);
// Add a request interceptor to include the access token in headers
api.interceptors.request.use(
    (config) => {
        // Get the access token from local storage
        const accessToken = localStorage.getItem('accessToken');
        
        // If the token exists, set it in the Authorization header
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


// 'http://localhost:5500/api'

//Auth
export const doLogin = data => api.post('/auth/login',data);
export const forgotPassword = data => api.post('/auth/forgot',data);
export const resetPassword = data => api.patch('/auth/reset',data);
export const dLogout = () => api.get('/auth/logout');

//Admin
export const getCounts = () => api.get('/admin/counts');
export const getEmployees = () => api.get('/admin/employees');
export const getLeaders = () => api.get('/admin/leaders');
export const getFreeLeaders = () => api.get('/admin/leaders/free');
export const getAdmins = () => api.get('/admin/admins');
export const getTeams = () => api.get('/admin/teams');

export const getTeamMembers = data => api.get(`/admin/team/${data}/members`);
export const addUser = data => api.post('/admin/user',data);
export const updateUser = (id,data) => api.patch(`/admin/user/${id}`,data);
export const addTeam = data => api.post('/admin/team',data);
export const updateTeam = (id,data) => api.patch(`/admin/team/${id}`,data);
export const getEmployee = data => api.get(`/admin/employee/${data}`);
export const getLeader = data => api.get(`/admin/leader/${data}`);
export const getFreeEmployees = () => api.get('/admin/employees/free');
export const getTeam = data => api.get(`/admin/team/${data}`);
export const removeMember = data => api.patch('/admin/team/member/remove',data);
export const addMember = data => api.patch('/admin/team/member/add',data);
export const removeLeader = data => api.patch('/admin/team/leader/remove',data);
export const addLeader = data => api.patch('/admin/team/leader/add',data);
export const getUser = data => api.get(`/admin/user/${data}`);
export const getAttendance = data => api.post('admin/view-employee-attendance',data);
export const viewLeaves = data => api.post('admin/view-leave-applications',data);
export const updateLeave = (id,data) => api.post(`admin/update-leave/${id}`,data);
export const assignSalary = data => api.post('admin/assign-employee-salary',data);
export const updateSalary = data => api.post('admin/update-employee-salary',data);
export const viewAllSalaries = data => api.post('admin/view-all-salary',data);
export const getAllUserExceptAdmin = () => api.get('/admin/getAllUserExceptAdmin');
export const addJobDetails = (data) => api.post('/admin/addJobDetails',data);

//Leader
export const getMembers_Leader = () => api.get('/leader/team/members');
export const getTeam_Leader = () => api.get('/leader/team/');


// Employee
export const getEmployeeTeam = data => api.get(`/employee/team/${data}`);
export const getEmployeeTeamMembers = data => api.get(`/employee/team/${data}/members`);
export const markEmployeeAttendance = data => api.post('/employee/mark-employee-attendance',data);
export const viewEmployeeAttendance = data => api.post('/employee/view-employee-attendance',data);
export const applyforleave = data => api.post('/employee/apply-leave-application',data);
export const viewLeaveApplicationsByEmployee = data => api.post('/employee/view-leave-applications-by-id',data);
export const viewLeaveApplicationByID = (id)=>api.get(`/admin/view-leave-by-applicationId/${id}`);


export const viewLeaveApplications = async (data) => {
    try {
        // Get the access token from local storage
        const accessToken = localStorage.getItem('accessToken');
        
        // Set up the headers, including the Authorization header
        const headers = {
            Authorization: `Bearer ${accessToken}`, // Use Bearer authentication
            'Content-Type': 'application/json' // Set content type if needed
        };

        // Send the POST request with data and headers
        const response = await api.post('/employee/view-leave-applications-by-id', data, { headers });

        // Handle the response (you can modify this based on your needs)
        console.log('Response:', response.data);
        return response.data; // Return the response data for further handling
    } catch (error) {
        // Handle errors (optional: improve error handling as needed)
        console.error('Error fetching leave applications:', error.response?.data || error.message);
        throw error; // Rethrow the error for further handling if needed
    }
};
export const viewEmployeeSalary = data => api.post('/employee/view-salary',data);


// Notifications 


export const fetchAllAdminNotificationsAPI = ()=> api.get('/notifications/all');

export const getPendingLeavesCount = ()=> api.get('/notifications/pendingLeaves');

export const userLeaveNotification = data => api.get(`/notifications/userLeaveNotification/${data}`);

export const notificationMarkAsRead = (userId)=> api.put(`/notifications/mark-all-as-read/${userId}`);

export const unreadNotificationCountByUserId = (userId)=> api.get(`/notifications//unreadNotificationCount/${userId}`);

export default api;
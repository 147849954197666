import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Admin from './Navigation/Admin';
import Leader from './Navigation/Leader';
import Employee from './Navigation/Employee';
import { useState, useEffect } from "react";
import { getPendingLeavesCount } from "../http";
import logo from "../../src/assets/img/logo.png";

const SideBar = () => {
  const [leavesCount, setLeavesCount] = useState(0);
  const { user } = useSelector(state => state.authSlice);

  useEffect(() => {
    if (user?.type === "Admin") {
      getPendingLeavesCount()
        .then(res => setLeavesCount(res.data))
        .catch(error => console.error("Failed to load pending leaves count:", error));
    }
  }, [user?.type]);

  return (
    <div className="main-sidebar min-vh-100 overflow-auto bg-light"> {/* Ensures full viewport height */}
      <aside id="sidebar-wrapper" className="bg-light d-flex flex-column"> {/* Flex layout for column alignment */}
        <div className="sidebar-brand">
          <NavLink to="/home">
          <img src={logo}  alt="Arkilos Logo" style={{width:"80px",marginBottom:"10px",paddingBottom:"10px"}} />
          </NavLink>
        </div>
        <div className="sidebar-brand sidebar-brand-sm" >
          {/* <NavLink to="/home">TM</NavLink> */}
        </div>  
        {(() => {
          switch (user?.type) {
            case 'Admin':
              return <Admin leaveCount={leavesCount} />;
            case 'Leader':
              return <Leader />;
            case 'Employee':
              return <Employee />;
            default:
              return null;
          }
        })()}
      </aside>
    </div>
  );
};

export default SideBar;

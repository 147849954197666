import { useState } from "react";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { applyforleave } from "../../http";
import Modal from '../../components/modal/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";

const ApplyForLeave = () => {
  const { user } = useSelector(state => state.authSlice); 
  const initialState = { title: '', type: '', startDate: '', endDate: '', reason: '' };
  const [formData, setFormData] = useState(initialState);

  // Get today's date in 'YYYY-MM-DD' format for the min attribute
  const today = new Date().toISOString().split("T")[0];

  const inputEvent = (e) => {
    const { name, value } = e.target;

    // Only allow alphabetic characters for title and reason
    if ((name === 'title' || name === 'reason') && /[^a-zA-Z\s]/.test(value)) {
      return toast.error("Only alphabetic characters are allowed in Title and Reason.");
    }

    setFormData((old) => ({
      ...old,
      [name]: value
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const { title, type, startDate, endDate, reason } = formData;

    // Basic validation check
    if (!title || !type || !startDate || !endDate || !reason) {
      return toast.error('All fields are required');
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    // Check if the start date is before the end date
    if (start > end) {
      return toast.error('Start date cannot be later than end date');
    }

    // Adding applicantID and appliedDate to form data
    const d = new Date();
    formData["applicantID"] = user.id;
    formData["appliedDate"] = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    try {
      const res = await applyforleave(formData);
      const { success } = res.data;
      if (success) {
        toast.success("Leave Application Sent!");
        setFormData(initialState);
      } else {
        toast.error("Failed to submit leave application.");
      }
    } catch (error) {
      console.error("Error applying for leave:", error);
      toast.error("An error occurred while submitting the application. Please try again.");
    }
  };

  return (
    <>
      <div className="main-content">
        <section className="section">
          <HeaderSection title='Apply for Leave' />
          <div className="card">
            <div className="card-body pr-5 pl-5 m-1">
              <form className='row' onSubmit={onSubmit} id='addUserForm'>
                <div className="form-group col-md-6">
                  <label>Enter Title</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-pen"></i>
                      </div>
                    </div>
                    <input onChange={inputEvent} value={formData.title} type="text" id='title' name='title' className="form-control" required/>
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <label>Leave Type</label>
                  <select name='type' onChange={inputEvent} value={formData.type} className="form-control select2" required>
                    <option>Select</option>
                    <option>Sick Leave</option>
                    <option>Casual Leave</option>
                    <option>Emergency Leave</option>
                  </select>
                </div>

                <div className="form-group col-md-6">
                  <label>Start Date</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa fa-calendar"></i>
                      </div>
                    </div>
                    <input onChange={inputEvent} value={formData.startDate} type="date" id="startDate" name="startDate" className="form-control" required min={today} />
                  </div>
                </div>

                <div className="form-group col-md-6">
                  <label>End Date</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fa fa-calendar"></i>
                      </div>
                    </div>
                    <input onChange={inputEvent} value={formData.endDate} type="date" id="endDate" name="endDate" className="form-control" required min={today} />
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <label>Enter Reason</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <i className="fas fa-book"></i>
                      </div>
                    </div>
                    <input onChange={inputEvent} value={formData.reason} type="text" id='reason' name='reason' className="form-control" required />
                  </div>
                </div>

                <div className="form-group text-center col-md-12">
                  <button className='btn btn-primary btn-lg' type='submit' style={{ width: '30vh' }}>Apply Leave</button>
                </div>

              </form>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ApplyForLeave;

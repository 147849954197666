import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuth } from "../store/auth-slice";
import axios from "axios";

export const useAutoLogin = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      const refreshToken = localStorage.getItem('refreshToken');

      if (!refreshToken) {
        setLoading(false);
        return;
      }

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/auth/refresh`,
          { refreshToken }
        );

        if (res.status === 200 && res.data.success) {
          console.log(res.data.accessToken);
          dispatch(setAuth(res.data.user));
          if (res.data.accessToken) {
            localStorage.setItem('accessToken', res.data.accessToken);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            localStorage.setItem('user',JSON.stringify(res.data.user));
          }
        } else {
          localStorage.removeItem('refreshToken');
        }
      } catch (err) {
        console.error('Error during auto login:', err);
        localStorage.removeItem('refreshToken');
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, [dispatch]);

  return loading;
};

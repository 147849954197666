import React, { useEffect, useState } from 'react';
import { getEmployees, getLeaders, viewAllSalaries } from '../../http';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';

const Salaries = () => {
  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const [employeeMap, setEmployeeMap] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [salaries, setSalaries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const salaryResponse = await viewAllSalaries({});
        const employeeResponse = await getEmployees();
        const leaderResponse = await getLeaders();

        const salaryData = salaryResponse.data.data;
        setSalaries(salaryData);

        const empObj = {};
        const allEmployees = [...employeeResponse.data.data, ...leaderResponse.data.data];
        allEmployees.forEach(employee => {
          empObj[employee.id] = [employee.name, employee.email];
        });
        
        setEmployeeMap(empObj);
        setEmployees(allEmployees);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const searchSalary = async () => {
    const searchParams = selectedEmployee ? { employeeID: selectedEmployee } : {};
    try {
      const res = await viewAllSalaries(searchParams);
      setSalaries(res.data.data);
    } catch (error) {
      console.error('Error searching salaries:', error);
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="main-content">
      <section className="section">
        <div className="card">
          <div className="card-header d-flex justify-content-between">
            <h4>Salaries</h4>
          </div>
        </div>
        
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="form-group col-md-6">
            <label>Employee</label>
            <select
              className="form-control select2"
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <option value="">Employees</option>
              {employees.map((employee) => (
                <option key={employee.id} value={employee.id}>
                  {employee.name}
                </option>
              ))}
            </select>
          </div>
          <button onClick={searchSalary} className="btn btn-lg btn-primary col">Search</button>
        </div>
      </section>
      
      <div className="table-responsive">
        <table className="table table-striped table-md center-text">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Salary</th>
              <th>Bonus</th>
            </tr>
          </thead>
          <tbody className="sidebar-wrapper">
            {salaries.map((salary, idx) => (
              <tr
                key={salary._id}
                className="hover-effect"
                onClick={() => history.push(`salary/${salary._id}`)}
              >
                <td>{idx + 1}</td>
                <td>{employeeMap[salary.employeeID]?.[0] || 'N/A'}</td>
                <td>{employeeMap[salary.employeeID]?.[1] || 'N/A'}</td>
                <td>{salary.salary}</td>
                <td>{salary.bonus}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Salaries;

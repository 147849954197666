import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuth: false,
    user: null,
    email: ''
};

export const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            const user = action.payload;
            state.user = user; // Set the user, whether null or valid
            state.isAuth = !!user; // Update isAuth based on user presence (true if user is not null)
        },
        setEmail: (state, action) => {
            const email = action.payload;
            state.email = email; // Set the email
        },
        clearAuth: (state) => {
            // Reset the authentication state
            state.isAuth = false;
            state.user = null;
            state.email = ''; // Clear the email if necessary
        }
    }
});

// Export the actions
export const { setAuth, setEmail, clearAuth } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeTeam } from "../../http";
import { NavLink } from 'react-router-dom';
import { setTeam } from '../../store/team-slice'; // Assuming this is from team-slice
import { setTeamMembers } from '../../store/user-slice'; // Assuming this is from user-slice
import NoDateFound from '../NoDateFound';
import Loading from '../Loading';

const UserTeams = () => {
  const dispatch = useDispatch();
  const userFromLocal = JSON.parse(localStorage.getItem('user'));
  const { user } = useSelector(state => state.authSlice);

  const [loading, setLoading] = useState(true);
  const [userTeam, setUserTeam] = useState(null);
  const [error, setError] = useState(null);

  const teamId = userFromLocal?.team?.id || userFromLocal?.team;

  useEffect(() => {
    // Dispatch actions to reset state in appropriate slices
    dispatch(setTeam(null)); // Clearing team state
    dispatch(setTeamMembers(null)); // Clearing team members state

    // Fetch team data if a team ID is available
    if (teamId) {
      (async () => {
        try {
          setLoading(true);
          const res = await getEmployeeTeam(teamId);
          if (res.data.success) {
            setUserTeam(res.data.data);
          } else {
            setError("Failed to fetch team details.");
          }
        } catch (err) {
          console.error("Error fetching team data:", err);
          setError("Error fetching team data. Please try again later.");
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [teamId, dispatch]);

  if (loading) return <NoDateFound />;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="main-content">
      <section className="section">
        <div className="card">
          <div className="card-header">
            <h4>All Teams</h4>
          </div>
          <div className="card-body p-0">
            <div className="table-responsive">
              <table className="table table-striped table-md center-text">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Leader</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userTeam ? (
                    <tr>
                      <td>1</td>
                      <td>
                        <figure className="avatar">
                          <img src={userTeam.image} alt={userTeam.name} />
                        </figure>
                      </td>
                      <td>{userTeam.name}</td>
                      <td>
                        {userTeam.leader ? (
                          <NavLink
                            to="/"
                            className="badge badge-primary"
                            style={{ padding: '0px 10px' }}
                          >
                            <img
                              src={userTeam.leader.image}
                              className="avatar avatar-sm mr-2"
                              alt="Leader"
                              width="96"
                              height="96"
                            />
                            {userTeam.leader.name}
                          </NavLink>
                        ) : (
                          <div
                            className="badge badge-light"
                            style={{ padding: '0px 10px' }}
                          >
                            <img
                              src="/assets/icons/user.png"
                              className="avatar avatar-sm mr-2"
                              alt="No Leader"
                              width="96"
                              height="96"
                            />
                            No Leader
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          className={`badge ${
                            userTeam.status === 'Active'
                              ? 'badge-primary'
                              : 'badge-danger'
                          }`}
                        >
                          {userTeam.status}
                        </div>
                      </td>
                      <td>
                        <NavLink
                          to={`/userteam/${userTeam.id}`}
                          className="btn btn-primary"
                        >
                          Detail
                        </NavLink>
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No team data available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserTeams;

import { useState } from "react";
import { NavLink } from "react-router-dom";
import { doLogin } from "../../http";
import { useDispatch } from "react-redux";
import {setAuth} from '../../store/auth-slice';
import { toast } from "react-toastify";
import { LuEye, LuEyeOff } from 'react-icons/lu';
const LoginForm = () =>
{  
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

    const dispatch = useDispatch();
    const [formData,setFormData] = useState({
        email:'',
        password:''
    });

    

    const inputEvent = (e) =>
    {
        const {name,value} = e.target;
        setFormData((old)=>
        {
            return {
                ...old,
                [name]:value
            }
        })
    }

    const onSubmit = async (e) => {
      e.preventDefault();
      const { email, password } = formData;
      console.log(email);
  
      if (!email || !password) {
          toast.error('All Fields Required');
          return;
      }
  
      try {
          // Attempt login
          const res = await doLogin({ email, password });
          const { success, accessToken, refreshToken, user } = res.data;
          console.log(res.data.user);
          
          if (success) {
              
              toast.success('Login Successful');
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('refreshToken', refreshToken);
              localStorage.setItem('userId', user.id);
              localStorage.setItem('user',JSON.stringify(user));
              const expirationTime = new Date().getTime() + 60 * 60 * 1000;  // 1 Hour Token
              localStorage.setItem('tokenExpiration', expirationTime);
              dispatch(setAuth(user));
          } else {
              toast.error("Invalid Credentials");
          }
      } catch (error) {
          // Handle errors, such as invalid credentials or server errors
          if (error.response && error.response.status === 401) {
              toast.error("Invalid Credentials");
          } else {
              toast.error("An error occurred during login. Please try again.");
          }
          console.error("Login error:", error);
      }
  };
  
  
    return(
        <div id="app">
        <section className="section">
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                <div className="login-brand">
                  <img src="https://www.pockethrms.com/wp-content/uploads/2022/01/Happy-Workforce.jpg" alt="logo" width="200" className=""/>
                </div>
                
                <div className="card card-primary">
                  <div className="card-header"><h4>Login</h4></div>
                  <div className="card-body">
                    <form onSubmit={onSubmit} className="needs-validation" noValidate="">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input id="email" onChange={inputEvent} value={formData.email} type="email" className="form-control" name="email" tabIndex="1" required autoFocus/>
                        <div className="invalid-feedback">
                          Please fill in your email
                        </div>
                      </div>
    
                      <div className="form-group">
                        <div className="d-block">
                            <label htmlFor="password" className="control-label">Password</label>
                          <div className="float-right">
                            <NavLink to='/forgot' className="text-small">
                              Forgot Password?
                            </NavLink>
                          </div>
                        </div>
                        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <input
        id="password"
        onChange={inputEvent}
        value={formData.password}
        type={showPassword ? 'text' : 'password'}
        className="form-control"
        name="password"
        tabIndex="2"
        required
        style={{ paddingRight: '2rem' }} // Adds space for the icon inside the input
      />
      <div
        onClick={togglePasswordVisibility}
        style={{
          position: 'absolute',
          right: '0.5rem', // Adjust as needed for positioning
          cursor: 'pointer',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'grey', // Optional: color for the icon
        }}
      >
        {showPassword ? <LuEyeOff /> : <LuEye />}
      </div>
    </div>
                        <div className="invalid-feedback">
                          please fill in your password
                        </div>
                      </div>
    
    
                      <div className="form-group">
                        <button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}

export default LoginForm;
import React from "react";

const NoDataFound = () => {
  return (
    <div className="main-content">
      <div className="section">
        <div
          className="loading"
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <i
            className="fas fa-database" // FontAwesome icon
            style={{ fontSize: "80px", color: "#6777ef", marginBottom: "20px" }}
          ></i>
          <h4>No Data Found</h4>
        </div>
      </div>
    </div>
  );
};

export default NoDataFound;

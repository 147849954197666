import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import HeaderSection from "../../components/HeaderSection";
import { updateUser, getUser } from "../../http";
import Modal from '../../components/modal/Modal';
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const EditUser = () => {
    // const initialState = {
    //     name: '',
    //     email: '',
    //     mobile: '',
    //     password: '',
    //     type: '',
    //     address: '',
    //     profile: '',
    //     status: ''
    // };
    
    const [imagePreview, setImagePreview] = useState('/assets/icons/user.png');
    const [formData, setFormData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [updateFormData, setUpdatedFormData] = useState({});
    const [userType, setUserType] = useState('User');
    const { id } = useParams();

    useEffect(() => {
        (async () => {
            const res = await getUser(id);
            console.log(res.data);
            if (res.data.success) {
                setUserType(res.data.data.type);
                setFormData(res.data.data);
                setImagePreview(res.data.data.image);
            }
        })();
    }, [id]);

    console.log(formData);
    const inputEvent = (e) => {
        const { name, value } = e.target;
        setFormData((old) => ({
            ...old,
            [name]: value
        }));
        setUpdatedFormData((old) => ({
            ...old,
            [name]: value
        }));

    };

    const captureImage = (e) => {
        const file = e.target.files[0];
        setFormData((old) => ({
            ...old,
            profile: file
        }));
        setUpdatedFormData((old) => ({
            ...old,
            profile: file
        }));
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImagePreview(reader.result);
        };
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (updateFormData.type && !showModal) return setShowModal(true);

        const fd = new FormData();
        // Append form data from state to FormData object
        for (let key in formData) {
            fd.append(key, formData[key]);
        }
        console.log(fd);
        
        // Append the image file if it exists
        // if (formData.profile) {
        //     fd.append('profile', formData.profile);
        // }

        // Log the FormData entries
        // for (let [key, value] of fd.entries()) {
        //     console.log(key, value);
        // }
        // fd.append('type',userType)
        const res = await updateUser(id, fd);
        console.log("Update User:", res.data.message);
        return (res.data.success) && toast.success(res.data.message);
    };

    const modalAction = () => setShowModal(!showModal);

    return (
        <>
            {showModal && 
                <Modal close={modalAction} title="Update User" width='35%'>
                    <div className="row" style={{ margin: '20px' }}>
                        <div className="col col-md-4">
                            <div className="input-group justify-content-center text-center">
                                <img className='rounded' src={imagePreview} width='120' alt="" /> 
                            </div>
                        </div>
                        <div className="col col-md-8">
                            <table className='table table-md'>
                                <tbody>
                                    <tr>
                                        <th>Name</th>
                                        <td>{formData.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{formData.email}</td>
                                    </tr>
                                    <tr>
                                        <th>User Type</th>
                                        <td>{formData.type}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="form-group col-md-12">
                        <label>Enter Your Password</label>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="fas fa-lock"></i>
                                </div>
                            </div>
                            <input onChange={inputEvent} value={formData.adminPassword} type="password" placeholder={`Enter Your Password To Change ${formData.name}'s Type`} id='adminPassword' name='adminPassword' className="form-control" />
                        </div>
                    </div>
                    <div className="justify-content-center text-center mb-3">
                        <button className='btn btn-primary btn-lg' type='submit' form='updateUserForm' style={{ width: '30vh' }}>Add {formData.type}</button>
                    </div>
                </Modal>
            }
            <div className="main-content">
                <section className="section">
                    <HeaderSection title={`Edit ${userType}`} />
                    {/* <NavLink to="/">Home</NavLink> */}
                    <div className="card">
                        <div className="card-body pr-5 pl-5 m-1">
                            <form className='row' onSubmit={onSubmit} id='updateUserForm'>
                                <div className="form-group col-md-12 text-center">
                                    <div className="input-group justify-content-center">
                                        <input type="file" id='profile' name='profile' className="form-control d-none" onChange={captureImage} accept="image/*" />
                                        <label htmlFor='profile'>
                                            <img className='rounded' src={imagePreview} width='120' alt={formData.name} />
                                        </label>
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Enter Name</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-user"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.name} type="text" id='name' name='name' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Enter Email</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-envelope"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.email} type="email" id='email' name='email' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Date Of Birth</label>
                                    <input onChange={inputEvent} value={ moment(formData.dob).format('YYYY-MM-DD')} type="date" id='dob' name='dob' className="form-control"/>
                                </div>
                                {/* <div className="form-group col-md-4">
                                    <label>Enter Username</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-user-circle"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.username} type="text" id='username' name='username' className="form-control" />
                                    </div> */}
                                {/* </div> */}
                                <div className="form-group col-md-2">
                                    <label>Gender</label>
                                    <select name='gender' onChange={inputEvent} value={formData.gender} className="form-control">
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-2">
                                    <label>Enter Mobile Number</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-phone"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.mobile} type="tel" id='mobile' name='mobile' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-2">
                                    <label>Enter Password</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-lock"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.password} type="password" id='password' name='password' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group col-md-2">
                                    <label>User Type</label>
                                    <select name='type' onChange={inputEvent} value={formData.type} className="form-control select2">
                                        <option>Employee</option>
                                        <option>Leader</option>
                                        <option>Admin</option>
                                    </select>
                                </div>

                                <div className="form-group col-md-2">
                                    <label>User Status</label>
                                    <select name='status' onChange={inputEvent} value={formData.status} className="form-control select2">
                                        <option>Active</option>
                                        <option>Banned</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-2">
                                    <label>Marital Status</label>
                                    <select name='marital' onChange={inputEvent} value={formData.marital} className="form-control">
                                        <option>Single</option>
                                        <option>Married</option>
                                    </select>
                                </div>
                                {/* Additional Fields */}
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Name</label>
                                    <input onChange={inputEvent} value={formData.emergencyContactName} type="text" id='contactName' name='contactName' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Relationship</label>
                                    <input onChange={inputEvent} value={formData.emergencyContactRelationship} type="text" id='contactRelationship' name='contactRelationship' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Emergency Contact Number</label>
                                    <input onChange={inputEvent} value={formData.emergencyContactNumber} type="number" id='contactNumber' name='contactNumber' className="form-control"/>
                                </div>
                                {/* <div className="form-group col-md-4">
                                    <label>SIN or PAN</label>
                                    <input onChange={inputEvent} value={formData.sin} type="text" id='sin' name='sin' className="form-control"/>
                                </div> */}
                                <div className="form-group col-md-4">
                                    <label>Nationality</label>
                                    <input onChange={inputEvent} value={formData.nationality} type="text" id='nationality' name='nationality' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Country</label>
                                    <input onChange={inputEvent} value={formData.country} type="text" id='country' name='country' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>State</label>
                                    <input onChange={inputEvent} value={formData.state} type="text" id='state' name='state' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>City</label>
                                    <input onChange={inputEvent} value={formData.city} type="text" id='city' name='city' className="form-control"/>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Postal Code</label>
                                    <input onChange={inputEvent} value={formData.postalCode} type="text" id='postalCode' name='postalCode' className="form-control"/>
                                </div>
                                <div className="form-group col-md-12 ">
                                    <label>Enter Address</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>
                                        </div>
                                        <input onChange={inputEvent} value={formData.address} type="text" id='address' name='address' className="form-control" />
                                    </div>
                                </div>

                                <div className="form-group text-center col-md-12">
                                    <button className='btn btn-primary btn-lg' type='submit' style={{ width: '30vh' }}>Update {userType}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default EditUser;
